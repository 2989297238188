body {
    background: url(white-noise.jpg) repeat;
    background-size: 200px;
    font-family: Arial, sans-serif;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  .time-component {
    display: inline-block;
    text-align: center;
    margin-right: 20px;
  }
  .time-digit {
    display: inline-block;
    font-size: 56px;
    font-weight: bold;
    color: #f2f2f2;
  }
  .time-label {
    display: block;
    font-size: 32px;
    color: black;
  }
  #earnings {
    font-size: 60px;
    font-weight: bolder;
    margin-top: 20px;
    color: red;
  }
  